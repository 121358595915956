module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"4a359a36-fef3-5c49-a0f9-45c24a5fd434","name":"gatsby-remark-images","version":"3.3.14","pluginOptions":{"plugins":[],"maxWidth":590},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-responsive-iframe","id":"1a3d9dbf-c3c7-50dc-9f59-97f0cf8bb163","name":"gatsby-remark-responsive-iframe","version":"2.4.7","pluginOptions":{"plugins":[],"wrapperStyle":"margin-bottom: 1.0725rem"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"80533a5b-ad2d-50ff-af51-726674869458","name":"gatsby-remark-copy-linked-files","version":"2.3.7","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-smartypants","id":"90305615-131b-5991-a708-2c2d1c43d946","name":"gatsby-remark-smartypants","version":"2.3.6","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":{"tsx":"tsx"},"aliases":{}}},{"resolve":"gatsby-remark-katex","options":{"strict":"ignore"}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147995495-3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bartolomej's website","short_name":"Bartolomej's website","start_url":"/","background_color":"#F5F7FB","theme_color":"#E84A5F","display":"minimal-ui","icon":"static/favicon.ico","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"eb695ea0e03df82c1f086c12ec2ba06a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
